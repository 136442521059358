<template>
	<div>
		<v-fade-transition group hide-on-leave>
			<template v-if="loadingParent">
				<v-skeleton-loader v-for="i in 5" :key="i" type="list-item-avatar-three-line" class="mx-auto mb-5 rounded-xl" max-width="1200" />
			</template>
			<template v-else>
				<FeedNotice v-for="notice in notices" :key="`notice${notice.id}`" :notice="notice" />
			</template>
		</v-fade-transition>

		<h3 v-if="!loadingParent && notices.length == 0" class="m-4">
			{{ $t('newsfeed.emptyFeedMessageOwn') }}
		</h3>
	</div>
</template>

<script>
export default {
	name: 'FeedNotices',
	props: {
		notices: {
			type: Array,
			required: true
		},
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	components: {
		FeedNotice: () => import('@/components/feed/FeedNotice.vue')
	}
}
</script>
